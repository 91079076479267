<template>
	<div v-if="!isMobile" class="card">
		<div v-if="cardData.position == 'top'" class="stick-up">
			<div class="circle" :style="`background: ${cardData.color}`">
				<div
					class="outer-circle"
					:style="`background: ${cardData.color}`"
				></div>
			</div>
			<div class="stick" :style="`background: ${cardData.color}`"></div>
			<div class="stick-cover" :style="`background: ${cardData.color}`"></div>
		</div>

		<div class="card-body h-100 d-flex flex-column justify-content-between">
			<div class="w-100">
				<p
					v-if="cardData.position == 'top'"
					class="date date-top"
					:style="`color: ${cardData.color}`"
				>
					{{ cardData.date }}
				</p>

				<p
					class="badge"
					:style="`color: ${cardData.color}; background: ${cardData.colorSecondary}`"
				>
					{{ cardData.id }}
				</p>
				<p class="text">{{ cardData.text }}</p>
			</div>

			<p
				v-if="cardData.position == 'bottom'"
				class="date"
				:style="`color: ${cardData.color}`"
			>
				{{ cardData.date }}
			</p>
		</div>

		<div v-if="cardData.position == 'bottom'" class="stick-down">
			<div
				class="stick-cover-down"
				:style="`background: ${cardData.color}`"
			></div>
			<div class="stick" :style="`background: ${cardData.color}`"></div>
			<div class="circle" :style="`background: ${cardData.color}`">
				<div
					class="outer-circle"
					:style="`background: ${cardData.color}`"
				></div>
			</div>
		</div>
	</div>

	<div v-else class="card">
		<div class="card-body h-100 d-flex flex-column justify-content-between">
			<div class="w-100">
				<p
					class="badge"
					:style="`color: ${cardData.color}; background: ${cardData.colorSecondary}`"
				>
					{{ cardData.id }}
				</p>
				<p class="text">{{ cardData.text }}</p>
			</div>

			<p class="date" :style="`color: ${cardData.color}`">
				{{ cardData.date }}
			</p>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		cardData: {
			type: Object,
			required: false,
		},
	},
	data() {
		return {
			isMobile: false,
			mobileBreakPoint: 1199,
		};
	},
	created() {
		this.resize();
		window.addEventListener("resize", () => {
			this.resize();
		});
	},
	methods: {
		resize() {
			window.innerWidth < this.mobileBreakPoint
				? (this.isMobile = true)
				: (this.isMobile = false);
		},
	},
};
</script>
<style scoped lang="scss">
.card {
	width: 250px;
	position: relative;
	margin: 35.5px 5px;
	box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
}
.outer-circle {
	height: 17px;
	width: 17px;
	opacity: 0.3;
	margin-left: -3.5px;
	margin-top: -3.5px;
	border-radius: 50%;
	position: absolute;
}
.circle {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	opacity: 1;
	position: relative;
	margin: 0;
	z-index: 999;
}
.stick-cover {
	position: absolute;
	width: 3px;
	height: 10px;
	bottom: 70%;
	left: 3.5px;
}
.stick {
	height: 32px;
	width: 3px;

	margin: auto;
	position: relative;
}
.stick-down {
	width: fit-content;
	position: absolute;
	left: 14px;
	top: 100.2%;
}
.stick-cover-down {
	position: absolute;
	width: 3px;
	height: 10px;
	top: 70%;
	left: 3.5px;
}
.stick-up {
	width: fit-content;
	position: absolute;
	right: 14px;
	bottom: 100.2%;
}

.card-body {
	margin: 0;
	padding: 13px;
}
.badge {
	font-family: Boing, sans-serif;
	height: 40px;
	width: 40px;
	font-weight: 700;
	font-size: 20px;
	margin: 13px 0;
	border-radius: 50%;

	display: flex;
	justify-content: center;
	align-items: center;
}

.text {
	opacity: 0.6;
	text-align: left;
	color: rgba(43, 46, 74, 1);
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
}

.date {
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	text-align: left;

	margin: 0;
}

.date-top {
	margin: 0 !important;
	text-align: right;
}
@media only screen and (max-width: 1199px) {
	.card {
		height: fit-content;
		min-height: 100%;
		width: 100%;
		margin: 0;
	}
	.stick-down,
	.stick-up {
		display: none;
	}
	.badge-top {
		position: absolute;
		left: 20px;
		top: 16px;
	}
	.text-top {
		margin-top: 56px;
		padding-top: 13px;
	}
}
@media only screen and (max-width: 576px) {
	.card {
		min-height: 250px;
	}
}
</style>
