<template>
	<div class="background">
		<div class="container">
			<div class="hero-big d-lg-flex d-none">
				<div>
					<h1 class="title title-first">Učitelia,</h1>
					<h1 class="title">posuňte sa vpred!</h1>
					<p class="text">
						Prihláste sa do nášho polročného rozvojového programu - Zmudri do škôl
					</p>
					<b-button class="log-in-button" @click="loginToProgram()" variant="primary">
						Prihlásiť sa do programu
					</b-button>
				</div>
				<div class="img-w-text">
					<img src="../assets/sec2.png" />
					<p>Termín prihlásenia je do 20.6. 23:59</p>
				</div>
			</div>
			<div class="hero-small d-lg-none d-block">
				<div class="img-w-text-mobile">
					<img src="../assets/sec2.png" alt="">
					<p>Termín prihlásenia je do 20.6. 23:59</p>
				</div>
				<div>
					<h1 class="title title-first">Učitelia,</h1>
					<h1 class="title">posuňte sa vpred!</h1>
					<p class="text">
						Prihláste sa do nášho polročného rozvojového programu - Zmudri do škôl
					</p>
					<b-button class="log-in-button" @click="loginToProgram()" variant="primary">
						Prihlásiť sa do programu
					</b-button>
				</div>
			</div>
		</div>
		<div class="splitter"></div>
	</div>
</template>
<script>
export default {
	methods: {
		loginToProgram() {
			window.open("https://forms.gle/UCarRSDyCbKHm5PG9")
		},
	},
}
</script>
<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.background {
	background: linear-gradient(180deg, rgba(246, 250, 252, 0) 0%, #f6fafc 100%);
}

.container {
	margin: 20px auto;
	margin-bottom: 0;
	padding-bottom: 0;
}
.hero-big {
	width: 100%;
	height: 495px;
	display: flex;
	justify-content: space-between;
}
.title-first {
	margin-top: 100px;

	@media only screen and (max-width: 1199px) {
		margin-top: 0;
	}
}
.title {
	font-size: 60px;
	font-weight: 700;
	margin-bottom: 6px;
}
.text {
	font-family: "Poppins";
	font-weight: 400;
	font-size: 16px;
	max-width: 370px;
	color: rgba(43, 46, 74, 0.6);
	margin: 24px 0 40px 0px;
	line-height: 24px;
}
.log-in-button {
	height: 56px;
	width: 267px;
}
.img-w-text{
	position: relative;
	height: 100%;
	max-width: calc(100% - 492px);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	// @media only screen and (min-width: 1399px){

	// }
	img {
		margin-top: auto;
		display: block;
		width: auto;
		max-height: 100%;
		max-width: 100%;
	}
	p {
		position: absolute;
		bottom: 10px;
		left: 50px;
		color: white;
		font-weight: 600;
	}

}
.img-w-text-mobile{
	position: relative;
	width: 100%;
	img {
		width: 100%;
	}

	p {
		position: absolute;
		bottom: 30px;
		left: 65px;
		margin-bottom: 40px;
		color: white;
		font-weight: 600;

		@media only screen and (max-width: 768px) {
			margin: 0;
			bottom: 60px;
			left: 50px;
			font-size: 12px;
		}
		@media only screen and (max-width: 576px) {
			bottom: 8%;
			left: 6.6%;
			font-size: 11px;
		}
		@media only screen and (max-width: 484px) {
			bottom: 8.8%;
			left: 5%;
			font-size: 9px;
		}
	}
}
.splitter {
	height: 2px !important;
	width: 100vw;
	background: rgba(243, 243, 243, 1);
	border: none;
}

@media only screen and (max-width: 1199px) {
	.hero-big {
		display: flex;
		height: fit-content;
	}
	.img-w-text{
		p {
			bottom: 4px;
			left: 40px;
			font-size: 11px;
		}
	}
}
@media screen and (max-width: 992px) {
	.container {
		padding: 0;
		margin-top: 0;
	}
	.hero-small {
		display: block;
		padding: 0 0 20px;
		text-align: center;
	}
	.text {
		margin: 30px auto;
	}
	img {
		width: 100%;
		max-width: 100%;
		height: 100%;
		object-fit: cover;
		margin: 0 0 40px;
	}
}
@media screen and (max-width: 575px) {
	img {
		margin: 0 0 10px;
	}
}
</style>
