<template>
	<div class="background">
		<div class="container">
			<div class="title justify-content-center w-100">
				<img src="../assets/section-title-img.svg" />
				<h1 class="title-text">Čo hovoria účastníci programu</h1>
			</div>
			<flickity
				ref="flickity"
				:options="flickityOptions"
				@init="onFlickityInit"
			>
				<b-card
					class="card"
					v-for="card in cards"
					:key="card.id"
					:class="{ 'cancel-clicks': isCarouselDragged }"
				>
					<img
						:src="
							require(`@/plugins/appzmudri@static/do-skol/assets/od-ucitelov/${card.url}`)
						"
						class="card-image d-block m-auto m-md-0"
					/>
					<b-card-body>
						<p class="name">{{ card.name }}</p>
						<p class="card-title">{{ card.title }}</p>
						<b-card-text class="text">{{ card.text }}</b-card-text>
					</b-card-body>
				</b-card>
			</flickity>

			<div class="d-flex justify-content-start buttons-wrapper">
				<a
					href="#"
					class="z-icon-wrapper -medium border"
					@click.prevent="previous()"
				>
					<svg-arrow-right-icon class="left-icon w-color-primary" />
				</a>
				<a
					href="#"
					class="z-icon-wrapper -medium border"
					@click.prevent="next()"
				>
					<svg-arrow-right-icon class="w-color-primary" />
				</a>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex"

export default {
	components: {
		flickity: () => import("vue-flickity"),
		"svg-arrow-right-icon": () =>
			import("@/plugins/lib/_theme/icon/arrow-right.svg?inline"),
	},

	computed: {
		...mapGetters("wAuth", ["isLoggedIn"]),
	},

	data() {
		return {
			flickityOptions: {
				prevNextButtons: false,
				cellAlign: "left",
				draggable: true,
				freeScroll: false,
				wrapAround: false,
			},
			cards: [
				{
					id: 1,
					url: "eva.jpg",
					name: "Eva Kadlečíková",
					title: "Obchodná akadémia Prievidza",
					text: "Na hodinách informatiky som využila materiály zo série Ako sa chrániť na internete. Metodiky boli spracované  pre študentov zaujímavo, videá sú zrozumiteľné, krátke a vtipné. Oceňujem, že materiály sú pripravené pre učiteľa tak, že ich môže použiť bez väčších úprav. Kvíz som zadávala žiakom cez Moju triedu na portáli Zmudri a tak som mala hneď aj spätnú väzbu. Ďakujem autorom a autorkám metodík a celému tímu Zmudri za túto pomoc pre nás učiteľov.",
				},
				{
					id: 2,
					url: "marta.jpg",
					name: "Marta Greňová",
					title: "SPŠ elektrotechnická Košice",
					text: "Chcela by som sa poďakovať celému tímu projektu Zmudri do škôl, ktorý pre nás učiteľov a našich žiakov vytvoril množstvo interaktívnych učebných materiálov. Scrollujem a hľadám - odborné vedomosti, kritické myslenie, osobnostný rozvoj, riešenie problémov,.... všetko, čo potrebujem pri práci so žiakmi a to všetko pod jednou strechou. Som veľmi vďačná, že som sa stala jeho súčasťou.",
				},
				{
					id: 3,
					url: "gizela.jpg",
					name: "Gizela Rešetárová",
					title: "Stredná zdravotnícka škola Kukučínova, Košice",
					text: "Portál Zmudri som spoznala počas dištančného vyučovania pri hľadaní návodu, ako natočiť video, aby bolo vyučovanie pútavejšie. Po pozretí zopár videí som od svojho zámeru rýchlo upustila. Na hodinách OBN som využila videá týkajúce sa práva, orgánov ochrany práva. Videá Zmudri sú výstižné, na vysokej odbornej úrovni, využívajú jazyk a štýl mladej generácie, páči sa mi jemná irónia. Oceňujem dobre prepracované metodické materiály (niektoré som využila celé, iné som si prispôsobila) a kvízy ku každému videu. Žiakom sa hodiny páčili, boli aktívni, riešili kvízy a písomku (súčasťou boli aj kvízové otázky) zvládli. Určite budem s portálom pracovať aj v ďalšom školskom roku.",
				},
				{
					id: 4,
					url: "michal.jpg",
					name: "Michal Soroka",
					title: "Gymnázium Štefana Moysesa, Moldava nad Bodvou",
					text: 'Prečo sa držať tradičnej formy vyučovania, ak je možnosť v rámci projektu ZMUDRI začať učiť moderným spôsobom, napríklad bádateľskou metódou. Nielen forma hodiny, ale aj audiovizuálny materiál, ktorý slúži ako pomôcka pre pedagógov, podporuje diskusiu žiaka a učiteľa. Moji študenti si tematický okruh s názvom "Ako sa chrániť na internete" obľúbili v rámci hodín mediálnej výchovy od prvej chvíle. Vrelo odporúčam každému jednému učiteľovi pridať sa ku vyše 7500 registrovaným pedagógom a smelo využívať učebné pomôcky tohto skvelého neziskového projektu.',
				},
			],
			isCarouselDragged: false,
		}
	},

	methods: {
		next() {
			this.$refs.flickity.next()
		},

		previous() {
			this.$refs.flickity.previous()
		},

		onFlickityInit() {
			// prevent clicking on carousel cards while dragging
			this.$refs.flickity.on(
				"dragStart",
				() => (this.isCarouselDragged = true)
			)
			this.$refs.flickity.on("dragEnd", () => (this.isCarouselDragged = false))

			setTimeout(
				function () {
					this.$refs.flickity.reloadCells()
				}.bind(this),
				1500
			)
		},
	},
}
</script>
<style lang="scss" scoped>
.card-image {
	height: 100px;
	width: 100px;
	border-radius: 50%;
}
.background {
	background: rgba(246, 250, 252, 1);
	margin: 100px auto 0;
	padding: 50px 0;
}
.container {
	margin: 0 auto;
	margin-bottom: 40px;
	padding-bottom: 0;
	.title {
		display: inline-flex;
		margin: 0 auto 40px;
		h1 {
			margin: 0;
			text-align: center;
		}
		img {
			margin-right: 10px;
		}
		@media only screen and (max-width: 575px) {
			align-items: center;
			img {
				width: 64px;
				height: 37px;
			}
			h1 {
				font-size: 26px;
			}
		}
	}
}
.flickity-slider {
	margin: 0 auto;
	max-height: fit-content;
}
.card {
	background: #fcfcfc;
	width: calc(33% - 25px);
	padding: 40px 40px 65px 40px;
	min-height: 100%;
	margin: 0 20px;

	img {
		object-fit: cover;
	}
	.card-body {
		padding: 0;
		p {
			margin-bottom: 0;
		}
		.name {
			margin-top: 30px;
			font-weight: 700;
			font-size: 16px;
			line-height: 25px;
			color: #2b2e4a;
		}
		.card-title {
			font-weight: 700;
			font-size: 16px;
			line-height: 25px;
			color: rgba(43, 46, 74, 0.4);
		}
		.text {
			margin-top: 16px;
			font-weight: 400;
			font-size: 16px;
			line-height: 25px;
			letter-spacing: -0.05em;
			color: #2b2e4a;
			opacity: 0.5;
		}
	}
}
.flicky-container {
	padding: 0 1rem;
	width: calc(100vw - ((100vw - 1140px) / 2));
	max-width: 100vw;
}

.buttons-wrapper {
	padding: 0 1rem;
	margin-top: 10px;
	width: 113px;

	.left-icon {
		transform: rotate(180deg);
	}

	.z-icon-wrapper {
		z-index: 1000;

		&:not(:first-of-type) {
			margin-left: 13px;
		}
	}
}

::v-deep {
	.flickity-page-dots {
		display: flex;
		align-items: center;
		margin-left: 120px;
		bottom: -35px;

		.flickity-viewport {
			overflow: hidden;
		}
		.dot {
			background: #0064ff;
		}

		&.is-selected {
			background: var(--a-color-primary, #333);
			width: 12px;
			height: 12px;
		}
	}
}

/* NOte: Useful to hide messy initial state */
.flickity-viewport {
	overflow: hidden;
}

.z-card {
	align-self: stretch;
}

.cancel-clicks {
	pointer-events: none;
}

.carousel-cell {
	margin-right: 30px;
	min-height: 100%;
	display: flex;
}

@media only screen and (min-width: 576px) {
	::v-deep .z-card {
		max-width: 438px !important;
		min-width: 438px !important;
	}
}

@media only screen and (max-width: 575px) {
	::v-deep .z-card {
		max-width: 300px !important;
		min-width: 300px !important;
	}
}
@media only screen and (max-width: 1199px) {
	.card {
		width: calc(50% - 20px);
	}
}

@media only screen and (max-width: 768px) {
	.card {
		width: 100%;
	}
}
</style>
