<template>
	<div class="content">
		<Nav class="navbar" />
		<Ucitelia-vpred />
		<O-programe />
		<Priebeh-programu />
		<Vyhody-programu />
		<Tematicke-moduly />
		<Tematicke-moduly-slovesnko />
		<Swiper />
		<Faq />
		<Partneri-programu />
		<Napisali-o-nas />
	</div>
</template>
<script>
import Nav from "./components/a-navbar-do-skol.vue"
import UciteliaVpred from "./components/a-ucitelia-posunte-sa-vpred.vue"
import OPrograme from "./components/a-o-programe.vue"
import PriebehProgramu from "./components/a-priebeh-programu.vue"
import VyhodyProgramu from "./components/a-vyhody-programu.vue"
import TematickeModuly from "./components/a-tematicke-moduly.vue"
import Faq from "./components/a-faq.vue"
import TematickeModulySlovesnko from "./components/a-tematicke-moduly-slovensko.vue"
import NapisaliONas from "./components/a-napisali-o-nas.vue"
import PartneriProgramu from "./components/a-partneri-programu.vue"
import Swiper from "./components/a-swiper.vue"

export default {
	components: {
		Nav,
		UciteliaVpred,
		OPrograme,
		PriebehProgramu,
		VyhodyProgramu,
		TematickeModuly,
		Faq,
		TematickeModulySlovesnko,
		NapisaliONas,
		PartneriProgramu,
		Swiper,
	},
}
</script>

<style scoped lang="scss">
.navbar {
	position: fixed;
	top: 0;
	z-index: 2000;
	padding: 0px;
}
.content {
	padding-top: 67px;
}
</style>
