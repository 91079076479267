<template>
	<div>
		<b-navbar
			fixed="top"
			toggleable="xl"
			class="d-flex justify-content-between align-items-center"
		>
			<b-navbar-nav class="-logo align-items-center">
				<b-navbar-brand @click="closeOverlays" to="/zmudri-do-skol">
					<img src="@/plugins/appzmudri@static/do-skol/assets/logo.svg" />
				</b-navbar-brand>
				<div class="a-v-line"></div>
			</b-navbar-nav>

			<div class="d-flex align-items-center">
				<b-navbar-toggle target="nav-collapse" ref="menuToggle">
					<div class="animated-hamburger">
						<span></span>
						<span></span>
						<span></span>
					</div>
				</b-navbar-toggle>
			</div>

			<b-collapse id="nav-collapse" is-nav @show="onShow" @hide="onHide">
				<b-button
					class="log-in-button"
					@click="loginToProgram()"
					variant="primary"
				>Prihlásiť sa do programu</b-button
				>
				<b-navbar-nav
					class="middle-nav align-items-xl-center justify-content-center"
				>
					<b-nav-item
						@click="closeOverlays"
						href="javascript:document.getElementById('priebeh-programu').scrollIntoView(true);"
						class="a-btn"
					>Priebeh programu</b-nav-item
					>
					<b-nav-item @click="closeOverlays" href="javascript:document.getElementById('moduly').scrollIntoView(true);" class="a-btn">
						Moduly
					</b-nav-item>
					<b-nav-item
						@click="closeOverlays"
						href="javascript:document.getElementById('vyhody-programu').scrollIntoView(true);"
						class="a-btn"
					>Čo získate</b-nav-item
					>
					<b-nav-item @click="closeOverlays" href="javascript:document.getElementById('faq').scrollIntoView(true);" class="a-btn">
						Otázky
					</b-nav-item>
				</b-navbar-nav>
				<b-button
					class="back-to-zmudri"
					href="https://zmudri.sk"
					variant="white"
				>Späť na Zmudri.sk</b-button
				>
			</b-collapse>
		</b-navbar>
		<z-breadcrumbs />
		<div
			class="overlay"
			:class="{ '-visible': isOverlayVisible }"
			@click="overlayClick"
		></div>
	</div>
</template>

<script>
import { mapGetters } from "vuex"

import Translations from "@/plugins/w/translate/translations.ts"

export default {
	components: {
		"z-breadcrumbs": () =>
			import("@/plugins/lib@_component/breadcrumbs/breadcrumbs.vue"),
	},

	data() {
		return {
			isOverlayVisible: false,
			isRolloverVisible: false,
			courses: null,
			currentLanguage: null,
		}
	},

	methods: {
		scrollTo(id) {
			console.log('testing')
			window.location.hash = id
		},

		async logout() {
			this.$wEvent.userLog("user.logout", { timestamp: new Date() })
			try {
				await this.$store.dispatch("wAuth/logout")
				if (this.$route.path != "/") this.$router.push("/")
			} catch (error) {
				this.$wToast.error(error)
			}
		},

		changeLanguage(lang) {
			Translations.setLocale(lang)
			this.currentLanguage = Translations.getLocale() == "en" ? "sk" : "en"
		},

		onShow() {
			this.isOverlayVisible = true
		},

		onHide() {
			const menuToggle = this.$refs.menuToggle.$el
			if (!menuToggle.classList.contains("not-collapsed")) {
				this.closeOverlays()
			}
		},

		collapseMenu() {
			const menuToggle = this.$refs.menuToggle.$el
			if (menuToggle.classList.contains("not-collapsed")) {
				menuToggle.click()
			}
		},

		openLoginProccess() {
			if (window.innerWidth < 1200) {
				if (this.$route.path != "/prihlasit-sa")
					this.$router.push("/prihlasit-sa")
				this.collapseMenu()
				return
			}
			window.scrollTo(0, 0)
			this.isOverlayVisible = !this.isOverlayVisible
			this.isRolloverVisible = !this.isRolloverVisible
		},

		navToRegistration() {
			this.closeOverlays()
			if (this.$route.path != "/registracia") this.$router.push("/registracia")
		},

		closeOverlays() {
			this.isOverlayVisible = false
			this.isRolloverVisible = false
		},

		overlayClick() {
			this.collapseMenu()
			this.closeOverlays()
		},

		loginToProgram() {
			window.open("https://forms.gle/UCarRSDyCbKHm5PG9")
		},
	},

	computed: {
		...mapGetters("wAuth", ["isLoggedIn", "user"]),
		isAdmin() {
			return (
				this.user && this.user.profile && this.user.profile.type == "teacher"
			)
		},
		isProfileSubpage() {
			const profileLinks = this.CONFIG.profile.profileLinks
			const profileSections = this.isAdmin
				? profileLinks.teacher
				: profileLinks.student
			return !!Object.values(profileSections).find((link) => {
				return this.$route.fullPath.indexOf(link.url) > -1
			})
		},
		isCoursesPage() {
			return this.$route.fullPath.indexOf("/kurzy") > -1
		},
		languages() {
			return Translations.getLocales()
		},
	},

	created() {
		this.EventBus.$on("locked-content-accessed", () => {
			this.openLoginProccess()
		})
		window.onresize = () => {
			if (window.innerWidth < 1200) {
				this.closeOverlays()
			}
		}
		this.currentLanguage = Translations.getLocale() == "en" ? "sk" : "en"
	},

	
	watch: {
		isLoggedIn() {
			this.closeOverlays()
		},
	},
}
</script>

<style lang="scss" scoped>
b-navbar {
	margin: 0 15px;
	background-color: white;
}
.overlay {
	display: none;

	&.-visible {
		display: block;
	}
}
.back-to-zmudri {
	border: 1px solid rgba(0, 100, 255, 0.15);
	color: var(--a-color-primary);
}

.a-v-line {
	height: 2.4rem;
	width: 1px;
	opacity: 15%;
	background-color: #0064ff;
	margin: 0 1.5rem;
}

.log-in-button {
	height: 40px;
	width: fit-content;
	padding: 8px, 32px, 8px, 32px;
	font-weight: 700;
	font-size: 16px;

	@media only screen and (max-width: 1200px) {
		margin-top: 15px;
	}
}

#login-collapse {
	position: absolute;
	left: 0;
	top: 72px;
	width: 100vw;

	@media only screen and (min-width: 1680px) {
		top: 120px;
	}

	&::v-deep {
		.card {
			border-radius: 0px;
			border: none;
		}

		.card-body {
			padding: 20px 0px 0px 0px;
			cursor: default;
			-webkit-box-shadow: 0px 60px 40px 20px rgba(0, 0, 0, 0.3);
			box-shadow: 0px 60px 40px 20px rgba(0, 0, 0, 0.3);
		}
	}

	.login-form {
		padding: 0px 300px;
	}
}

.navbar-light .navbar-nav .nav-link {
	color: #2b2e4a;
}
nav {
	width: 100vw;
	background-color: #ffffff;
	position: relative;

	z-index: 1030;
	padding: 8px 3rem;
	box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);

	.router-link-active {
		font-weight: bold;
	}

	.a-btn:hover {
		color: inherit;
		text-decoration: none;
	}

	::v-deep .a-btn,
	.btn {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
	@media only screen and (max-width: 575px) {
		.logo {
			max-width: 6rem;
		}
	}
}

.overlay {
	position: fixed;
	opacity: 0;
	top: 0;
	left: 0;
	width: 200vw;
	height: 200vh;
	content: "";
	z-index: -1;
	background-color: rgba(var(--a-color-black-rgb), 0.6);
	transition: 0.5s ease;

	&.-visible {
		z-index: 1029;
		opacity: 1;
	}
}

.navbar-toggler {
	border-width: 0;
	padding: 0.2rem 0.5rem;

	.animated-hamburger {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: column;
		width: 2rem;
		height: 1.5rem;

		span {
			display: block;
			height: 4px;
			width: 100%;
			background: var(--a-color-primary);
			border-radius: 9px;
			opacity: 1;
			left: 0;
			transform: rotate(0deg);
			transition: 0.25s ease-in-out;

			&:nth-child(1) {
				transform-origin: right center;
			}

			&:nth-child(2) {
				transform-origin: right center;
			}

			&:nth-child(3) {
				transform-origin: right center;
			}
		}
	}

	&.not-collapsed {
		.animated-hamburger {
			span {
				&:nth-child(1) {
					transform: rotate(-45deg) translate(0px, 0px);
				}

				&:nth-child(2) {
					width: 0%;
					opacity: 0;
				}

				&:nth-child(3) {
					transform: rotate(45deg) translate(3px, 2px);
				}
			}
		}
	}

	svg {
		height: 2rem;
		width: 2rem;
	}
}

.nav-link {
	white-space: nowrap;
	font-family: "Boing", sans-serif;
}

.left-nav {
	max-width: 20%;
}

.logo {
	max-width: 100%;
}

.middle-nav {
	flex-grow: 1;
}

.search-form {
	flex: 1 0 0;
	max-width: 545px;

	form {
		width: 100%;
	}
}

.profile-icon {
	max-height: 22px;
}

.navbar-icon {
	margin-left: 0.75rem;
	svg {
		height: 40px;
		width: 40px;
	}
}

.language-select {
	text-transform: uppercase;
}

@media only screen and (max-width: 1400px) {
	nav {
		.a-btn {
			padding: 0.5rem;
		}

		.a-v-line {
			margin: 0 0.5rem;
		}
	}
}

@media only screen and (max-width: 1199px) {
	.navbar-nav {
		&.-logo {
			flex-direction: row;

			::v-deep .dropdown-menu {
				position: absolute;
			}
		}
	}
}

@media only screen and (max-width: 575px) {
	.a-v-line {
		height: 2.75rem;
	}
}

.opacity-100,
.opacity-100::v-deep * {
	color: var(--a-color-black);
}
</style>
