<template>
	<div class="container">
		<b-row>
			<div class="centered">
				<img src="../assets/section-title-img.svg"/>
				<h1 class="title-text">Napísali o nás</h1>
			</div>
			<div class="box-container">
				<div v-for="(item, index) in svgArr" :key="index" class="box">
					<img :src="item.logo" />
				</div>
			</div>
		</b-row>
	</div>
</template>

<script>
export default {
	data() {
		return {
			svgArr: [
				{
					logo: require("@/plugins/appzmudri/about/_assets/icons/media/forbes_ptxlkr 1.svg")
				},
				{
					logo: require("@/plugins/appzmudri/about/_assets/icons/media/markiza_verzia-1 1.svg")
				},
				{
					logo: require("@/plugins/appzmudri/about/_assets/icons/media/tv-joj1.svg")
				},
				{
					logo: require("@/plugins/appzmudri/about/_assets/icons/media/ta31.svg")
				},
				{
					logo: require("@/plugins/appzmudri/about/_assets/icons/media/rtvs1.svg")
				},
				{
					logo: require("@/plugins/appzmudri/about/_assets/icons/media/radio-expres 1.svg")
				},
				{
					logo: require("@/plugins/appzmudri/about/_assets/icons/media/fun-radio 1.svg")
				},
				{
					logo: require("@/plugins/appzmudri/about/_assets/icons/media/fici_qzj9hu 1.svg")
				},
				{
					logo: require("@/plugins/appzmudri/about/_assets/icons/media/startitup_logo_yykuce1.svg")
				},
				{
					logo: require("@/plugins/appzmudri/about/_assets/icons/media/sme_ugv0j31.svg")
				},
				{
					logo: require("@/plugins/appzmudri/about/_assets/icons/media/refresher_vdxibe1.svg")
				},
				{
					logo: require("@/plugins/appzmudri/about/_assets/icons/media/dobre-noviny 1.svg")
				},
				{
					logo: require("@/plugins/appzmudri/about/_assets/icons/media/tyzden1.svg")
				},
				{
					logo: require("@/plugins/appzmudri/about/_assets/icons/media/korzar 1.svg")
				},
				{
					logo: require("@/plugins/appzmudri/about/_assets/icons/media/trend1.svg")
				},
				{
					logo: require("@/plugins/appzmudri/about/_assets/icons/media/dobra-skola_jurdru 1.svg")
				},
				{
					logo: require("@/plugins/appzmudri/about/_assets/icons/media/zones_sk_logo_k7iscc1.svg")
				}
			]
		}
	}
}
</script>

<style lang="scss" scoped>

.container{
	margin: 100px auto;
	margin-bottom: 50px;
	padding-bottom: 0;
	@media only screen and (max-width: 575px) {
		margin: 50px auto;
	}
}
.centered {
	z-index: 1000;
	display: flex;
	width: 100%;
	justify-content: center;
	margin-bottom: 2rem;
	h1{
		margin: auto 0 auto 10px;
	}
	@media only screen and (max-width: 575px) {
		text-align: center;
		img{
			width: 64px;
		}
		h1{
			margin: 0 0 0 10px;
			font-size: 26px;

		}
	}
}

.box-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.box {
	height: 7rem;
	width: 220px;
	display: flex;
	justify-content: center;
	img {
		place-self: center center;
		width: auto;
		height: auto;
		margin:1rem;
	}
}
</style>
